import * as React from 'react'
import { Units } from '@app/constants'
import { format } from 'd3-format'

const UnitValue = ({ value, unit }: {
  value: string | number,
  unit: Units,
}) => {
  switch (unit) {
    case Units.FEET:
      return format(',.0f')(value)
    case Units.SQUARE_FEET:
      return format(',.0f')(value)
    default:
      if ((typeof value === 'string' && value.trim() === '') || !value) {
        return <i>None</i>
      } else {
        return value
      }
  }
}

export default UnitValue
