import * as React from 'react'
import { Units } from '@app/constants'

const UnitLabel = ({ unit }: { unit: Units }) => {
  switch (unit) {
    case Units.FEET:
      return <span>ft</span>
    case Units.SQUARE_FEET:
      return <span>ft<sup>2</sup></span>
    default:
      return <></>
  }
}

export default UnitLabel
