import loki from 'lokijs'
import * as _ from 'lodash'

interface IFindParams {
  collectionId: string,
  query: any,
}

class Database {
  lokidb: LokiConstructor

  async initialize () {
    const databaseResponse = await fetch(
      process.env.SITE_URL + '/tiles/database.json'
    )

    this.lokidb = new loki('data')
    this.lokidb.loadJSON(await databaseResponse.json())
  }

  first (params: IFindParams) {
    return this.find(params)[0]
  }

  find (params: IFindParams) {
    const results = _.find(this.lokidb.collections, {
      name: params.collectionId,
    }).find(params.query)

    for (const result of results) {
      delete result.meta
      delete result.$loki
    }

    return results
  }
}

export default Database
