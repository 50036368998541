import * as React from 'react'
import { observer } from 'mobx-react'
import { strings } from '@app/constants'

const LoadingBadge = () => (
  <svg width='150' height='50' className='text-white'>
    <rect rx='20' ry='20'
      width='150' height='40'
      fill='#555'></rect>
    <g className='fill-current'>
      <text y='25' x='22'>{strings.LOADING}</text>
      <g transform='translate(96, 20)'>
        <circle cx='0' cy='0' r='5' id='c1'>
          <animateTransform
            attributeName='transform'
            attributeType='XML'
            type='scale'
            values='0.75 0.75;1 1;0.75 0.75'
            dur='2s' begin='0.0s'
            repeatCount='indefinite'/>
          <animate repeatCount='indefinite'
            attributeName='opacity'
            values='0;1;0' dur='2s'
            begin='0.0s' />
        </circle>
      </g>
      <g transform='translate(111, 20)'>
        <circle cx='0' cy='0' r='5' id='c1'>
          <animateTransform
            attributeName='transform'
            attributeType='XML'
            type='scale'
            values='0.75 0.75;1 1;0.75 0.75'
            dur='2s' begin='0.1s'
            repeatCount='indefinite'/>
          <animate repeatCount='indefinite'
            attributeName='opacity'
            values='0;1;0' dur='2s'
            begin='0.1s' />
        </circle>
      </g>
      <g transform='translate(126, 20)'>
        <circle cx='0' cy='0' r='5' id='c1'>
          <animateTransform
            attributeName='transform'
            attributeType='XML'
            type='scale'
            values='0.75 0.75;1 1;0.75 0.75'
            dur='2s' begin='0.2s'
            repeatCount='indefinite'/>
          <animate repeatCount='indefinite'
            attributeName='opacity'
            values='0;1;0' dur='2s'
            begin='0.2s' />
        </circle>
      </g>
    </g>
  </svg>
)

export default observer(LoadingBadge)
