import 'babel-polyfill'

Math.log10 = Math.log10 || ((x) => (
  Math.log(x) * Math.LOG10E
))

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from '@app/components/App'

require('@scss/styles.scss')
require('mapbox-gl/dist/mapbox-gl.css')

const div = document.createElement('div')
div.className = 'h-screen w-screen'
document.body.appendChild(div)

ReactDOM.render(
  <App />,
  div
)
