import { observable, computed, toJS } from 'mobx'
import { throttledComputed } from 'computed-async-mobx'
import * as _ from 'lodash'
import Database from '@app/models/Database'
import NumberRange from '@app/models/NumberRange'
import { layerInfo } from '@app/utils'

class VectorLayer {
  @observable id: string
  @observable name: string
  @observable metadata: object
  @observable swatchColor: string
  @observable layerOptions: object
  @observable visible: boolean = true
  database: Database
  readonly ranges = observable<NumberRange>([])

  constructor ({
    id,
    name,
    metadata,
    swatchColor,
    layerOptions,
    ranges,
    database,
  }: {
    id: string
    name: string
    metadata: object
    swatchColor: string
    layerOptions: object
    ranges: any[]
    database: Database
  }) {
    this.id = id
    this.name = name
    this.metadata = metadata
    this.swatchColor = swatchColor
    this.layerOptions = layerOptions
    this.database = database

    if (ranges.length > 0) {
      this.ranges.replace(ranges.map(range => {
        range.data = this.database.find({
          collectionId: this.id,
          query: {},
        })
          .map(entry => entry[range.id])
          .filter(x => _.isNumber(x))

        return new NumberRange(range)
      }))
    }
  }

  _selectedIds = throttledComputed(() => {
    const conditions: any = {}
    for (const range of this.ranges) {
      conditions[range.id] = { '$between': [...range.values] }
    }

    const results = this.database.find({
      collectionId: this.id,
      query: {
        '$and': [conditions],
      },
    })

    return results.map(({ id }) => id)
  }, 10)

  @computed get allIds () {
    return this.database.find({
      collectionId: this.id,
      query: {},
    }).map(({ id }: { id: any }) => id)
  }

  @computed get selectedIds () {
    return this._selectedIds.get()
  }

  @computed get mapStyle () {
    return {
      ...layerInfo(this.id),
      ...toJS(this.layerOptions),
      ...this.filter,
      layout: {
        visibility: (
          (this.visible ? 'visible' : 'none') as ('visible' | 'none')
        ),
      },
    }
  }

  @computed get filter () {
    return this.ranges.length > 0
      ? {
        'filter': [
          'match',
          ['id'],
          [...this.selectedIds],
          true,
          false,
        ],
      }
      : {}
  }
}

export default VectorLayer
