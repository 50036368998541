import * as React from 'react'
import * as _ from 'lodash'
import { inject, observer } from 'mobx-react'
import RootStore from '@app/models/RootStore'
import UnitValue from '@app/components/UnitValue'
import UnitLabel from '@app/components/UnitLabel'
import constants, { Units } from '@app/constants'

interface IEntityDisplayItem {
  name: string
  id: string
  unit: Units
}

const ContextPanel = ({ rootStore }: {
  rootStore?: RootStore,
}) => {
  const selected = rootStore.context.selected
  const layer = selected && _.find(constants.LAYERS, { id: selected.layerId })
  const items = selected && (layer.entityDisplay.items as IEntityDisplayItem[])

  return (
    <div className='w-80 bg-grey-lightest shadow-layer z-10 overflow-y-auto'>
      {
        selected && (
          <div className='m-4'>
            <p className='flex-grow pr-1 pb-1 font-light text-xl'>
              {layer.entityDisplay.name}
            </p>
            {
              items.map((item) => {
                const value = selected.attributes.get(item.id)
                return item && (
                  <div key={item.id}>
                    <div className='mt-2'>
                      {item.name}
                    </div>
                    <div className='font-light'>
                      <UnitValue value={value} unit={item.unit} />
                      <UnitLabel unit={item.unit} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default inject('rootStore')(observer(ContextPanel))
