import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { strings } from '@app/constants'
import RootStore from '@app/models/RootStore'
import Bars from '@app/components/icons/Bars'
import Times from '@app/components/icons/Times'

const MoreInfoHeader = ({ rootStore }: { rootStore?: RootStore }) => (
  <div className={`
    flex flex-col w-80 cursor-pointer hover:bg-blue-dark
  `} onClick={() => {
    rootStore.moreInfoOpen = !rootStore.moreInfoOpen
  }}>
    <div className='flex items-center'>
      <div className={`
        flex-grow flex p-4 py-5 text-sm
        items-baseline leading-none
      `}>
        <span className='pr-1 inline-block' style={{
          position: 'relative',
          top: '0.15em',
        }}>
          {
            rootStore.moreInfoOpen
              ? <Times className='h-4 w-4' />
              : <Bars className='h-4 w-4' />
          }
        </span>
        <span>{strings.MORE_INFO}</span>
      </div>
      <img className='p-1 h-10' src='/img/CityPerspective.svg' />
    </div>
  </div>
)

export default inject('rootStore')(observer(MoreInfoHeader))
