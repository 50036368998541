import * as React from 'react'
import Helmet from 'react-helmet'
import { inject, observer } from 'mobx-react'
import { strings } from '@app/constants'
import Loading from '@app/components/Loading'
import Viewer from '@app/components/Viewer'
import RootStore from '@app/models/RootStore'

const Container = ({ rootStore }: {
  rootStore?: RootStore
}) => (
  <>
    <Helmet title={strings.HEADING}>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='icon' type='image/png' href='/img/favicon.png' />
      <link href='https://fonts.googleapis.com/css?family=Heebo:300,400,500' rel='stylesheet' />
      <script async src='https://www.googletagmanager.com/gtag/js?id=UA-123748252-1'></script>
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-123748252-1');
      `}</script>
    </Helmet>
    {
      rootStore.initialized ? (
        <Viewer />
      ) : (
        <Loading />
      )
    }
  </>
)

export default inject('rootStore')(observer(Container))
