import * as mapboxgl from 'mapbox-gl'

interface IFeaturePropertyReplaceParams {
  map: mapboxgl.Map,
  previousLayerId: string,
  previousId: number,
  layerId: string,
  id: number,
}

export const setHovered = (
  replaceParams: IFeaturePropertyReplaceParams
) => {
  replaceFeatureProperty({ replaceParams, property: 'hovered' })
}

export const setSelected = (
  replaceParams: IFeaturePropertyReplaceParams
) => {
  replaceFeatureProperty({ replaceParams, property: 'selected' })
}

const replaceFeatureProperty = ({
  replaceParams,
  property,
}: {
  replaceParams: IFeaturePropertyReplaceParams,
  property: string,
}) => {
  const {
    map,
    previousLayerId,
    previousId,
    layerId,
    id,
  } = replaceParams

  if (previousId !== undefined) {
    map.setFeatureState({
      source: previousLayerId,
      sourceLayer: previousLayerId,
      id: previousId,
    }, { [property]: false })
  }

  if (id !== undefined) {
    map.setFeatureState({
      source: layerId,
      sourceLayer: layerId,
      id,
    }, { [property]: true })
  }
}
