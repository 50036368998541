import * as React from 'react'
import { observer } from 'mobx-react'
import LoadingBadge from '@app/components/LoadingBadge'

const Loading = () => (
  <div className={`
    w-screen h-screen flex
    loading font-sans font-light
    justify-center items-center
  `}>
    <LoadingBadge />
  </div>
)

export default observer(Loading)
