import * as React from 'react'
import { reaction, IReactionDisposer } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as mapboxgl from 'mapbox-gl'
import { viewport } from '@mapbox/geo-viewport'
import * as _ from 'lodash'
import { sourceFromMetadata } from '@app/utils'
import { setHovered, setSelected } from '@app/featureStateUtils'
import RootStore from '@app/models/RootStore'
import BoundingBox from '@app/models/BoundingBox'
import constants from '@app/constants'
import PerspectiveSwitch from '@app/mapbox-plugins/PerspectiveSwitch'

// tslint:disable-next-line
(mapboxgl as any).accessToken = 'pk.eyJ1Ijoia2oxMjM0NSIsImEiOiJjamllb2gwb20wb2Z0M2twZTl2aGttaHlxIn0.0JoeAePlpOWqX31yS8fgxA'

class MapComponent extends React.Component<{
  width: number,
  height: number,
  rootStore?: RootStore
}, any> {
  mapRef: React.RefObject<any> = React.createRef()
  map: mapboxgl.Map
  styleUpdatePending: boolean = false
  styleUpdateReactionDisposer: IReactionDisposer

  componentDidMount () {
    const { rootStore } = this.props
    const boundsLayer = _.find(constants.LAYERS, {
      id: constants.INITIAL_BOUNDS_LAYER,
    })

    const source = sourceFromMetadata(boundsLayer)
    const bounds = BoundingBox.fromArray(source.bounds)
    const rect = this.mapRef.current.getBoundingClientRect()
    const mapSize: [number, number] = [rect.height, rect.width]

    const initialBounds = bounds.scaled(constants.INITIAL_BOUNDS_SCALE).array
    const maxBounds = bounds.scaled(constants.MAX_BOUNDS_SCALE).array

    // TODO: remove the cast to any once geo-viewport types are updated
    const view = (viewport as any)(initialBounds, mapSize, 0, 20, 512, true)

    this.map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: rootStore.mapStyle,
      zoom: view.zoom,
      minZoom: view.zoom - constants.MIN_ZOOM_OFFSET,
      center: view.center,
      maxBounds,
      pitch: 0,
    })

    { (this.map as any).style._validate = () => false }

    this.map.addControl(new PerspectiveSwitch(), 'top-right')
    this.map.addControl(new mapboxgl.NavigationControl())

    this.setupMapMouseEvents()

    this.styleUpdateReactionDisposer = reaction(() => ({
      style: rootStore.mapStyle,
    }), () => {
      this.updateStyle()
    })
  }

  setupMapMouseEvents () {
    const { rootStore } = this.props

    this.map.on('mousemove', (e) => {
      const features = this.map.queryRenderedFeatures(e.point)

      if (features.length > 0) {
        const feature: any = features[0]
        setHovered({
          map: this.map,
          previousLayerId: _.get(rootStore.context.hovered, 'layerId'),
          previousId: _.get(rootStore.context.hovered, 'id'),
          layerId: feature.sourceLayer,
          id: feature.id,
        })

        rootStore.context.hover(feature.sourceLayer, feature.id)
      }
    })

    this.map.on('mouseout', () => {
      if (rootStore.context.hovered !== undefined) {
        setHovered({
          map: this.map,
          previousLayerId: _.get(rootStore.context.hovered, 'layerId'),
          previousId: _.get(rootStore.context.hovered, 'id'),
          layerId: undefined,
          id: undefined,
        })

        rootStore.context.hover(undefined, undefined)
      }
    })

    this.map.on('click', () => {
      if (rootStore.context.hovered !== undefined) {
        setSelected({
          map: this.map,
          previousLayerId: _.get(rootStore.context.selected, 'layerId'),
          previousId: _.get(rootStore.context.selected, 'id'),
          layerId: _.get(rootStore.context.hovered, 'layerId'),
          id: _.get(rootStore.context.hovered, 'id'),
        })

        rootStore.context.select(
          _.get(rootStore.context.hovered, 'layerId'),
          rootStore.context.hovered
        )
      }
    })
  }

  asyncUpdateStyle = () => {
    this.styleUpdatePending = true
    window.requestAnimationFrame(() => {
      if (this.map.loaded()) {
        this.styleUpdatePending = false
        this.map.setStyle(this.props.rootStore.mapStyle)
      } else {
        this.asyncUpdateStyle()
      }
    })
  }

  updateStyle () {
    if (this.mapRef.current) {
      if (!this.styleUpdatePending) {
        this.asyncUpdateStyle()
      }
    }
  }

  componentWillUnmount () {
    this.map.remove()
    this.styleUpdateReactionDisposer()
  }

  render () {
    return <div className='w-full h-full' ref={this.mapRef} />
  }
}

export default inject('rootStore')(observer(MapComponent))
