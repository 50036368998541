import { observable, computed } from 'mobx'
import { scaleLog } from 'd3-scale'
import * as _ from 'lodash'
import { Units } from '@app/constants'

class NumberRange {
  @observable id: string
  @observable name: string
  @observable unit: Units
  @observable dataMin: number
  @observable dataMax: number
  @observable _valuesMin: number
  @observable _valuesMax: number

  data: number[]

  constructor ({ id, name, unit, data }: {
    id: string
    name: string
    unit: Units
    data: number[]
  }) {
    this.id = id
    this.name = name
    this.unit = unit
    this.data = data

    this.dataMin = _.max([1, _.min(this.data)])
    this.dataMax = _.max(this.data)

    this.values = [this.xScale().domain()[0], this.xScale().domain()[1]]
  }

  // How far through the range is the center of the selected values?
  @computed get centerPercentage () {
    const percentScale = this.xScale(100)
    return (percentScale(this.values[0]) + percentScale(this.values[1])) / 2
  }

  @computed get values () {
    return [this._valuesMin, this._valuesMax]
  }

  // Set values, clamping the values to be in range
  set values ([valuesMin, valuesMax]: [number, number]) {
    // keep values in range
    valuesMin = Math.max(valuesMin, this.xScale().domain()[0])
    valuesMax = Math.min(valuesMax, this.xScale().domain()[1])

    // keep min value less than max
    valuesMin = Math.min(valuesMin, valuesMax)

    this._valuesMin = valuesMin
    this._valuesMax = valuesMax
  }

  // Set values, but leave unchanged if out of range
  setValuesIfInRange (min: number, max: number) {
    if (
      min <= max &&
      min > this.xScale().domain()[0] &&
      max < this.xScale().domain()[1]
    ) {
      this.values = [min, max]
    }
  }

  xScale (width: number = 100) {
    return scaleLog()
      .base(10)
      .domain([this.dataMin, this.dataMax])
      .range([0, width])
      .clamp(true)
  }
}

export default NumberRange
