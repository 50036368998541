import Database from '@app/models/Database'

class Entity {
  id: number
  layerId: string
  attributes: Map<string, any>

  constructor ({ id, layerId, attributes }: {
    id: number,
    layerId: string,
    attributes: {}
  }) {
    this.id = id
    this.layerId = layerId
    this.attributes = new Map(Object.entries(attributes))
  }

  static fromDatabase ({ id, layerId, database }: {
    id: number,
    layerId: string,
    database: Database,
  }) {
    const attributes = database.first({
      collectionId: layerId,
      query: { id },
    })

    return new Entity({
      id,
      layerId,
      attributes,
    })
  }
}

export default Entity
