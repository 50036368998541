import * as React from 'react'
import { format } from 'd3-format'
import { area, curveBasis } from 'd3-shape'
import { observer } from 'mobx-react'

const TICK_HEIGHT = 5

const Chart = ({ xScale, yScale, kde, rangeColor }: {
  xScale: any,
  yScale: any,
  kde: [number, number][],
  rangeColor: string,
}) => {
  const startTick = Math.ceil(Math.log10(xScale.domain()[0]))
  const endTick = Math.floor(Math.log10(xScale.domain()[1]))
  const ticks: number[] = []
  const skip = (endTick - startTick) > 5 ? 2 : 1
  for (let i = startTick; i <= endTick; i += skip) {
    ticks.push(Math.pow(10, i))
  }

  const kdePath = area()
    .curve(curveBasis)
    .x((d: any) => xScale(d[0]))
    .y0(yScale(0) + 1)
    .y1((d: any) => yScale(d[1]))

  return (
    <g>
      <rect x={0} y={0}
        width={xScale.range()[1]} height={yScale.range()[0]}
        fill='#fff' stroke='#e0e0e0' />
      <path fill={rangeColor}
        d={kdePath(kde)} transform='translate(0, -1.5)' />
      {
        ticks.map((tick: any) => (
          <g key={tick} className='text-grey-dark'
            transform={`translate(${xScale(tick)}, ${yScale.range()[1]})`}>
            <rect
              key={tick}
              fill='currentColor'
              x={-0.5} y={-TICK_HEIGHT}
              width={1} height={TICK_HEIGHT} />
            <text x={3} y={-(TICK_HEIGHT + 2)} style={{
              'textAnchor': 'end',
              'fontSize': '12px',
            }} className='fill-current'>{format(',')(tick)}</text>
          </g>
        ))
      }
    </g>
  )
}

export default observer(Chart)
