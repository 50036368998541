import * as React from 'react'
import { inject, observer } from 'mobx-react'
import classnames from 'classnames'
import RootStore from '@app/models/RootStore'
import MoreInfoHeader from '@app/components/MoreInfoHeader'
import File from '@app/components/icons/File'
import Speech from '@app/components/icons/Speech'
import constants, { strings } from '@app/constants'

const MoreInfo = ({ rootStore }: { rootStore?: RootStore }) => (
  <div className={classnames(`
    w-screen h-screen flex flex-col
    text-grey-darkest font-sans
    absolute pin-t z-30
  `, {
    'opacity-0 pointer-events-none': !rootStore.moreInfoOpen,
  })} style={{
    background: '#0008',
    transition: 'opacity 0.2s',
  }}>
    <div className='flex h-screen'>
      <div className='flex-grow'
        onClick={() => { rootStore.moreInfoOpen = false }} />
      <div className={`
        z-20 antialiased shadow-layer
        flex flex-col
        items-stretch flex-no-shrink w-80
      `}>
        <div className='bg-blue text-white'>
          <MoreInfoHeader />
        </div>
        <div className='flex-grow bg-white p-4 pt-1'>
          <p className='my-8'>
            <a className='text-blue-dark no-underline'
              href={constants.LEARN_MORE_URL}>
              <span><File className='w-4 h-4 -mb-2px pr-1' /> </span>
              {strings.LEARN_MORE}
            </a>
          </p>
          <p className='my-8'>
            <a className='text-blue-dark no-underline'
              href={constants.CONTACT_US_URL}>
              <span><Speech className='w-4 h-4 -mb-2px pr-1'/> </span>
              {strings.CONTACT_US}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default inject('rootStore')(observer(MoreInfo))
