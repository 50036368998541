import * as React from 'react'
import { reaction, IReactionDisposer } from 'mobx'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import Point from '@app/models/Point'
import NumberRange from '@app/models/NumberRange'

const BAR_RADIUS = 9

class Slider extends React.Component<{
  range: NumberRange,
  mousePosition: Point,
  previousMousePosition: Point,
  dragging: boolean,
  startDragging: (dragOptions: any) => void,
  dragOptions: any,
  xScale: any
  yScale: any
}, any> {
  dragReactionDisposer: IReactionDisposer
  groupRef: React.RefObject<any> = React.createRef()

  componentDidMount () {
    this.dragReactionDisposer = reaction(() => ({
      ...this.props.mousePosition,
    }), () => {
      const {
        xScale,
        mousePosition,
        previousMousePosition,
        dragging,
        dragOptions,
        range,
      } = this.props

      if (mousePosition && dragging) {
        const deltaX = mousePosition.x - previousMousePosition.x
        const left = this.groupRef.current.getBoundingClientRect().left
        const minPosition = xScale.invert(mousePosition.x - left + BAR_RADIUS / 2)
        const maxPosition = xScale.invert(mousePosition.x - left - BAR_RADIUS / 2)

        switch (dragOptions.type) {
          case 'MINIMUM':
            range.values = [minPosition, range.values[1]]
            break
          case 'MAXIMUM':
            range.values = [range.values[0], maxPosition]
            break
          case 'RANGE':
            range.setValuesIfInRange(
              xScale.invert(xScale(range.values[0]) + deltaX),
              xScale.invert(xScale(range.values[1]) + deltaX),
            )
            break
        }
      }
    })
  }

  componentWillUnmount () {
    this.dragReactionDisposer()
  }

  render () {
    const { xScale, yScale, range, dragging, startDragging } = this.props

    return (
      <g className={classnames({
        'slider': true,
        'dragging': dragging,
      })}ref={this.groupRef}>
        <rect className='bg'
          x={xScale.range()[0]} y={0}
          width={xScale.range()[1] - xScale.range()[0]}
          height={yScale.range()[0]} />
        <rect className='unselected'
          x={xScale.range()[0]} y={0}
          width={xScale.range()[0] + xScale(range.values[0])}
          height={yScale.range()[0]} />
        <rect className='unselected'
          x={xScale(range.values[1])} y={0}
          width={xScale.range()[1] - xScale(range.values[1])}
          height={yScale.range()[0]} />
        <rect className='bar'
          onMouseDown={() => { startDragging({ type: 'RANGE' }) }}
          x={xScale(range.values[0])} y={yScale.range()[0] - BAR_RADIUS}
          width={xScale(range.values[1]) - xScale(range.values[0])}
          height={BAR_RADIUS * 2} />
        <g className='handle' transform={`translate(
          ${xScale(range.values[0])},
          ${yScale.range()[0]}
        )`}>
          <path d={`
              M0 -${BAR_RADIUS}
              A ${BAR_RADIUS} ${BAR_RADIUS}, 0, 0, 0, 0 ${BAR_RADIUS}
              L0 -${BAR_RADIUS}
            `}
            onMouseDown={() => { startDragging({ type: 'MINIMUM' }) }} />
        </g>
        <g className='handle' transform={`translate(
          ${xScale(range.values[1])},
          ${yScale.range()[0]}
        )`}>
          <path d={`
              M0 ${BAR_RADIUS}
              A ${BAR_RADIUS} ${BAR_RADIUS}, 0, 0, 0, 0 -${BAR_RADIUS}
              L0 ${BAR_RADIUS}
            `}
            onMouseDown={() => { startDragging({ type: 'MAXIMUM' }) }} />
        </g>
      </g>
    )
  }
}

export default observer(Slider)
