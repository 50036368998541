import * as React from 'react'
import * as scale from 'd3-scale'
import { observer } from 'mobx-react'
import { kernelDensityEstimator, kernelEpanechnikov } from '@app/utils'
import MouseElement from '@app/components/MouseElement'
import Chart from '@app/components/rangeSlider/Chart'
import Slider from '@app/components/rangeSlider/Slider'
import NumberRange from '@app/models/NumberRange'

const NUM_KDE_SAMPLES = 50
const KDE_WINDOW = 0.1

const Control = ({ width, height, range, rangeColor }: {
  width: number,
  height: number,
  range: NumberRange,
  rangeColor: string,
}) => {
  if (width === undefined || height === undefined) {
    return <></>
  }

  const margin = { top: 12, right: 0, bottom: 0, left: 5 }
  const chartWidth = width - margin.left - margin.right
  const chartHeight = height - margin.top - margin.bottom

  const xScale = range.xScale(chartWidth)

  const kdeStartSample = Math.log10(range.dataMin)
  const kdeEndSample = Math.log10(range.dataMax)
  const kdeStep =  (kdeEndSample - kdeStartSample) / NUM_KDE_SAMPLES
  const kdeSamples: number[] = []
  for (let i = kdeStartSample; i <= kdeEndSample; i += kdeStep) {
    kdeSamples.push(i)
  }

  const logData = range.data.map(x => Math.log10(x))
  const estimator = kernelDensityEstimator(
    kernelEpanechnikov(KDE_WINDOW),
    kdeSamples,
  )

  const kde: [number, number][] = estimator(logData).map(
    x => ([Math.pow(10, x[0]), x[1]] as [number, number])
  )

  const yMax = Math.max(...kde.map(((x: any) => x[1]))) * 1.25
  const yScale = scale.scaleLinear()
    .domain([0, yMax])
    .range([chartHeight - 1, 0])

  return (
    <svg width={width} height={height}
      className='range-slider select-none'
      style={{ overflow: 'visible' }}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <Chart
          xScale={xScale}
          yScale={yScale}
          kde={kde}
          rangeColor={rangeColor} />
        <MouseElement render={({
          mousePosition,
          previousMousePosition,
          dragging,
          dragOptions,
          startDragging,
        }) => (
          <Slider
            mousePosition={mousePosition}
            previousMousePosition={previousMousePosition}
            dragging={dragging}
            dragOptions={dragOptions}
            startDragging={startDragging}
            range={range}
            xScale={xScale}
            yScale={yScale} />
        )} />
      </g>
    </svg>
  )
}

export default observer(Control)
