export const strings = {
  HEADING: 'Commerce City',
  SUBHEADING: 'GIS Data Viewer',
  LOADING: 'Loading',
  OVERHEAD: 'Overhead',
  PERSPECTIVE: '3D',
  MORE_INFO: 'More Info',
  LEARN_MORE: 'Learn about City Perspective',
  CONTACT_US: 'Contact Us',
}

const FEET_PER_METER = 3.28084

export enum Units {
  FEET,
  SQUARE_FEET,
  NONE,
}

export default {
  LEARN_MORE_URL: 'https://visualperspective.io/',
  CONTACT_US_URL: 'https://visualperspective.io/contact',
  PERSPECTIVE_PITCH: 20,
  INITIAL_BOUNDS_LAYER: 'streets',
  INITIAL_BOUNDS_SCALE: 0.75,
  MIN_ZOOM_OFFSET: 1,
  MAX_BOUNDS_SCALE: 3,
  UI_LAYER_ORDER: [1, 0, 2],
  LAYERS: [
    {
      id: 'buildingroofprints',
      name: 'Building Roofprints',
      metadata: require('@tiles/buildingroofprints/metadata.json'),
      swatchColor: 'hsl(220, 70%, 60%)',
      layerOptions: {
        'type': 'fill-extrusion',
        'paint': {
          'fill-extrusion-height': ['/', ['get', 'Bldg_Height'], FEET_PER_METER],
          'fill-extrusion-color': ['case',
            ['boolean', ['feature-state', 'selected'], false],
            'hsl(220, 70%, 35%)',
            ['case',
              ['boolean', ['feature-state', 'hovered'], false],
              'hsl(220, 70%, 50%)',
              'hsl(220, 70%, 65%)',
            ],
          ],
        },
      },
      ranges: [
        {
          id: 'shapestarea',
          name: 'Area',
          unit: Units.SQUARE_FEET,
        },
        {
          id: 'bldg_height',
          name: 'Height',
          unit: Units.FEET,
        },
        {
          id: 'grnd_elevation',
          name: 'Ground Elevation',
          unit: Units.FEET,
        },
      ],
      entityDisplay: {
        name: 'Building Roofprint',
        items: [
          { name: 'Area', id: 'shapestarea', unit: Units.SQUARE_FEET },
          { name: 'Height', id: 'bldg_height', unit: Units.FEET },
          { name: 'Ground Elevation', id: 'grnd_elevation', unit: Units.FEET },
        ],
      },
    },
    {
      id: 'streets',
      name: 'Streets',
      metadata: require('@tiles/streets/metadata.json'),
      swatchColor: 'hsl(0, 0%, 100%)',
      layerOptions: {
        'type': 'line',
        'paint': {
          'line-color':  ['case',
            ['boolean', ['feature-state', 'selected'], false],
            'hsl(0, 0%, 50%)',
            ['case',
              ['boolean', ['feature-state', 'hovered'], false],
              'hsl(0, 0%, 70%)',
              'hsl(0, 0%, 100%)',
            ],
          ],
          'line-width': [
            'interpolate', ['linear'], ['zoom'],
            9, 0.25,
            15, 4,
          ],
        },
      },
      ranges: [],
      entityDisplay: {
        name: 'Street',
        items: [
          { name: 'Full Name', id: 'st_fullname', unit: Units.NONE },
          { name: 'Surface', id: 'surface', unit: Units.NONE },
        ],
      },
    },
    {
      id: 'parcels',
      name: 'Parcels',
      metadata: require('@tiles/parcels/metadata.json'),
      swatchColor: 'hsl(120, 25%, 70%)',
      layerOptions: {
        'type': 'fill',
        'paint': {
          'fill-color': ['case',
            ['boolean', ['feature-state', 'selected'], false],
            'hsl(120, 12%, 70%)',
            ['case',
              ['boolean', ['feature-state', 'hovered'], false],
              'hsl(120, 12%, 80%)',
              'hsl(120, 12%, 90%)',
            ],
          ],
          'fill-outline-color': 'hsl(120, 12%, 60%)',
        },
      },
      ranges: [
        {
          id: 'shapestarea',
          name: 'Area',
          unit: Units.SQUARE_FEET,
        },
      ],
      entityDisplay: {
        name: 'Parcel',
        items: [
          { name: 'Address', id: 'addr_complete', unit: Units.NONE },
          { name: 'Area', id: 'shapestarea', unit: Units.SQUARE_FEET },
        ],
      },
    },
  ],
}
