import { observable } from 'mobx'
import Database from '@app/models/Database'
import Entity from '@app/models/Entity'

class Context {
  @observable hovered: Entity
  @observable selected: Entity
  database: Database

  constructor ({ database }: {
    database: Database
  }) {
    this.database = database
  }

  hover (layerId: string, entity: number | Entity) {
    this.hovered = this.getEntity(layerId, entity)
  }

  select (layerId: string, entity: number | Entity) {
    this.selected = this.getEntity(layerId, entity)
  }

  getEntity (layerId: string, entity: number | Entity) {
    if (entity === undefined) {
      return undefined
    } else if (entity instanceof Entity) {
      return entity
    } else {
      return Entity.fromDatabase({
        id: entity,
        layerId,
        database: this.database,
      })
    }
  }
}

export default Context
