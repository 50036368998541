import * as React from 'react'
import { format } from 'd3-format'
import classnames from 'classnames'
import { inject, observer } from 'mobx-react'
import VectorLayer from '@app/models/VectorLayer'
import Eye from '@app/components/icons/Eye'
import RangeSlider from '@app/components/rangeSlider/RangeSlider'

const LayerItem = ({ layer }: {
  layer: VectorLayer,
}) => (
  <div className={classnames({
    '-mt-px': true,
    'border-b': true,
    'border-t': true,
    'border-grey-light': true,
    'opacity-50': !layer.visible,
    'pb-6': layer.ranges.length > 0,
  })}>
    <div className='m-4'>
      <div className='flex'>
        <div style={{
          backgroundColor: layer.swatchColor,
          width: '1.5em',
          height: '1.5em',
          borderRadius: '0.2em',
          position: 'relative',
          bottom: '0.05em',
          boxShadow: '0 0 1px #0007',
        }} />
        <span className={classnames({
          'flex-grow': true,
          'pl-2': true,
          'pr-1': true,
          'pb-1': layer.ranges.length > 0,
          'font-light': true,
          'text-xl': true,
        })}>{layer.name}</span>
        <span
          className={classnames({
            'pl-1': true,
            'pt-1': true,
            'hover:text-blue-light': true,
            'cursor-pointer': true,
            'rounded-sm': true,
          })}
          onClick={() => { layer.visible = !layer.visible }}>
          <Eye className='w-4 h-4' />
        </span>
      </div>
      {
        layer.ranges.length > 0 &&
          <div className='text-sm text-grey-dark pt-1'>
            <span>Showing {format(',')(layer.selectedIds.length)} </span>
            <span>of {format(',')(layer.allIds.length)}</span>
          </div>
      }
    </div>
    {
      layer.ranges.map((range) => (
        <RangeSlider
          key={range.id}
          range={range}
          rangeColor={layer.swatchColor} />
      ))
    }
  </div>
)

export default inject('rootStore')(observer(LayerItem))
