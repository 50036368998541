import { observable, computed } from 'mobx'
import constants from '@app/constants'
import * as mapboxgl from 'mapbox-gl'
import VectorLayer from '@app/models/VectorLayer'
import Context from '@app/models/Context'
import Database from '@app/models/Database'
import { sourceFromMetadata } from '@app/utils'

const mapStyle: mapboxgl.Style = {
  'version': 8,
  'name': 'Empty',
  'metadata': {
    'mapbox:autocomposite': true,
  },
  'glyphs': 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  'sources': {},
  'layers': [],
}

const defaultLayers: mapboxgl.Layer[] = [
  {
    'id': 'background',
    'type': 'background',
    'paint': {
      'background-color': 'hsl(0, 0%, 92%)',
    },
  },
]

class RootStore {
  @observable initialized: boolean = false
  @observable moreInfoOpen: boolean = false
  context: Context
  database: Database
  readonly layers = observable<VectorLayer>([])

  async initialize () {
    this.database = new Database()
    await this.database.initialize()

    this.context = new Context({ database: this.database })

    const layersConfig = constants.LAYERS.map((layer: any) => ({
      ...layer,
      database: this.database,
    }))

    this.layers.replace(layersConfig.map(layer => (
      new VectorLayer(layer)
    )))

    this.initialized = true
  }

  @computed get mapStyle () {
    return {
      ...mapStyle,
      sources: this.layers.reduce((obj: any, layer) => {
        obj[layer.id] = sourceFromMetadata(layer)
        return obj
      }, {}),
      layers: defaultLayers.concat(
        this.layers.reduce((arr: any, layer: any) => {
          arr.unshift(layer.mapStyle)
          return arr
        }, [])
      ),
    }
  }
}

export default RootStore
