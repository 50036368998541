import * as React from 'react'
import { inject, observer } from 'mobx-react'
import RootStore from '@app/models/RootStore'
import LayerItem from '@app/components/LayerItem'
import constants from '@app/constants'

const LayerList = ({ rootStore }: {
  rootStore?: RootStore,
}) => (
  <div className='w-80 bg-grey-lightest shadow-layer z-10 overflow-y-auto'>
    {
      constants.UI_LAYER_ORDER.map(i => (
        <LayerItem key={rootStore.layers[i].id} layer={rootStore.layers[i]} />
      ))
    }
  </div>
)

export default inject('rootStore')(observer(LayerList))
