import * as React from 'react'
import { observer } from 'mobx-react'
import { strings } from '@app/constants'
import LayerList from '@app/components/LayerList'
import ContextPanel from '@app/components/ContextPanel'
import MapComponent from '@app/components/MapComponent'
import SizedElement from '@app/components/SizedElement'
import MoreInfoHeader from '@app/components/MoreInfoHeader'
import MoreInfo from '@app/components/MoreInfo'

const Viewer = () => (
  <>
    <div className={`
      w-screen h-screen flex flex-col items-stretch
      text-grey-darkest font-sans
    `}>
      <div className={`
        bg-blue text-white
        z-20 antialiased shadow-layer flex
        items-stretch flex-no-shrink
      `}>
        <h1 className={`
          p-4 flex flex-grow text-2xl font-medium antialiased
          leading-none items-center
        `}><span>{strings.HEADING}</span></h1>
        <MoreInfoHeader />
      </div>
      <div className='flex flex-grow min-h-0 items-stretch'>
        <LayerList />
        <SizedElement
          className='flex-grow relative'
          render={({ width, height }) => (
            <MapComponent width={width} height={height} />
          )} />
        <ContextPanel />
      </div>
    </div>
    <MoreInfo />
  </>
)

export default observer(Viewer)
