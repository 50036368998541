import * as React from 'react'
import { observer } from 'mobx-react'
import SizedElement from '@app/components/SizedElement'
import UnitValue from '@app/components/UnitValue'
import UnitLabel from '@app/components/UnitLabel'
import Control from '@app/components/rangeSlider/Control'
import NumberRange from '@app/models/NumberRange'

const RangeSlider = ({ range, rangeColor }: {
  range: NumberRange,
  rangeColor: string,
}) => (
  <div className='px-4 mt-6 font-light'>
    <div className='flex pb-1'>
      <span className='flex-grow'>
        {range.name}
        <span> (<UnitLabel unit={range.unit} />)</span>
      </span>
    </div>
    <div className='py-1'>
      <SizedElement render={({ width }) => (
        <Control
          width={width}
          height={75}
          range={range}
          rangeColor={rangeColor} />
      )} />
    </div>
    <div className='overflow-hidden pt-1'>
      <div style={{
        marginLeft: `${range.centerPercentage}%`,
      }}>
        <div className={`
          text-grey-dark text-sm inline-block
          whitespace-no-wrap
        `} style={{
          transform: `translateX(-${range.centerPercentage}%)`,
        }}>
          <span>Filtered to </span>
          <UnitValue unit={range.unit} value={range.values[0]} />
          <UnitLabel unit={range.unit} />
          <span> &ndash; </span>
          <UnitValue unit={range.unit} value={range.values[1]} />
          <UnitLabel unit={range.unit} />
        </div>
      </div>
    </div>
  </div>
)

export default observer(RangeSlider)
