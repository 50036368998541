import { mean } from 'd3-array'

export const sourceFromMetadata = ({ id, metadata }: {
  id: string,
  metadata: any,
}) => ({
  'type': 'vector',
  'tiles': [process.env.SITE_URL + `/tiles/${id}/{z}/{x}/{y}.pbf`],
  'minzoom': Number(metadata.minzoom),
  'maxzoom': Number(metadata.maxzoom),
  'bounds': metadata.bounds.split(',').map((x: string) => Number(x)),
})

export const layerInfo = (name: string) => ({
  'id': `${name}layer`,
  'source': `${name}`,
  'source-layer': `${name}`,
})

export const kernelDensityEstimator = (kernel: any, X: any) => (
  (V: any): [number, number][] => (
    X.map((x: any) => (
      [x, mean(V, (v: any) => (kernel(x - v)))]
    ))
  )
)

export const kernelEpanechnikov = (k: any) => (
  (v: any) => (
    Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0
  )
)
