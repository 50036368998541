import * as mapboxgl from 'mapbox-gl'
import constants, { strings } from '@app/constants'

class PerspectiveSwitch {
  container: HTMLElement
  overhead: HTMLElement
  perspective: HTMLElement
  map: mapboxgl.Map

  insertControls () {
    this.container = document.createElement('div')
    this.container.classList.add('mapboxgl-ctrl')
    this.container.classList.add('mapboxgl-ctrl-group')
    this.container.classList.add('mapboxgl-ctrl-horizontal')
    this.overhead = document.createElement('button')
    this.overhead.classList.add('overhead')
    this.overhead.textContent = strings.OVERHEAD
    this.perspective = document.createElement('button')
    this.perspective.classList.add('perspective')
    this.perspective.textContent = strings.PERSPECTIVE
    this.container.appendChild(this.overhead)
    this.container.appendChild(this.perspective)
  }

  onAdd (map: mapboxgl.Map) {
    this.map = map
    this.insertControls()
    this.overhead.addEventListener('click', () => {
      this.map.flyTo({
        pitch: 0,
        bearing: 0,
      })
    })
    this.perspective.addEventListener('click', () => {
      this.map.flyTo({
        pitch: constants.PERSPECTIVE_PITCH,
        bearing: 0,
      })
    })
    return this.container
  }

  onRemove () {
    this.container.parentNode.removeChild(this.container)
    this.map = undefined
  }
}

export default PerspectiveSwitch
